//@import reset, fonts, vars, mixins
@font-face {
  font-family: 'museo300';
  src: url('../fonts/MuseoSansCyrl-300.eot');
  src: url('../fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'), url('../fonts/MuseoSansCyrl-300.woff') format('woff'), url('../fonts/MuseoSansCyrl-300.ttf') format('truetype'), url('../fonts/MuseoSansCyrl-300.svg#museo_sans_cyrl300') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'museo900';
  src: url('../fonts/MuseoSansCyrl-900.eot');
  src: url('../fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'), url('../fonts/MuseoSansCyrl-900.woff') format('woff'), url('../fonts/MuseoSansCyrl-900.ttf') format('truetype'), url('../fonts/MuseoSansCyrl-900.svg#museo_sans_cyrl300') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

*, *:before, *:after {
  box-sizing: border-box; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block; }

body {
	line-height: 1; }

ol, ul {
	list-style: none; }

blockquote, q {
	quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none; }

table {
	border-collapse: collapse;
	border-spacing: 0; }

$font300: museo300;
$font900: museo900;
$htmlbg: #EBEBEB;
$headbg: #111226;
$contentbg: #15171E;
$textColor: #101527;

@mixin equalGradient {
  background: linear-gradient(0deg, #DF302A, #FF6516); }

@mixin angleGradient {
  background: linear-gradient(45deg, #DF302A, #FF6516);
  transform: skewX(-10deg); }

html {
  background-color: $htmlbg;
  font-size: 20px;
  font-family: $font300; }

.content {
  max-width: 1386px;
  padding: 0 10px;
  margin: 0 auto; }

a {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.5s ease; }

header a {
  color: #fff; }


.head-wrap {
  background-color: $contentbg;
  .content, .contacts {
    display: flex;
    justify-content: space-between; }
  .logo-wrap {
    position: relative;
    a {
      display: block;
      width: 220px;
      max-height: 80px;
      margin: 10px 0; }
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%; } } }

.contacts {
  padding: 22px 0;
  color: #fff;
  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      color: #f05f17; } }
  >div {
    margin-left: 25px; }
  .contacts-ico {
    float: left;
    width: 22px;
    height: 26px;
    margin: 15px 10px 15px 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    &.ico-mail {
      background-image: url(../images/mail.svg);
      margin: 11px 15px 19px 15px; }
    &.ico-loc {
      background-image: url(../images/loc.svg); }
    &.ico-phone {
      background-image: url(../images/phone.svg); } }
  .contacts-text {
    float: right;
    line-height: 26px; }
  .contacts-text.in-one-line {
    line-height: 48px; } }

.nav {
  background-color: $headbg;
  font-family: $font300;
  ul {
    display: flex;
    justify-content: center;
    li {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: rgba(235,235,235,.1); }
      &:first-child::before {
        display: none; }
      a {
        display: block;
        padding: 15px 35px;
        &:hover {
          background-color: rgba(240, 95, 23, .6); } } } } }

.breadcrumbs {
  padding: 150px 0 50px 0;
  color: #fff;
  position: relative;
  .bread-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;
    background-position: center;
    background-size: cover; }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(21,23,30,.7);
    z-index: -1; }
  h1 {
    font-family: $font900;
    margin-bottom: 25px;
    font-size: 2rem; }
  li {
    display: inline-block;
    position: relative;
    &::after {
      content: '>';
      color: #FF6516; }
    &:last-child::after {
      display: none; }
    a {
      color: #fff; } } }

//section1
.section-wrap {
  position: relative; }

.main-slider {
  height: 600px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,1);
    z-index: -1; } }

.upper-slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2; }
.slider {
  position: relative;
  max-width: 1366px;
  margin: 0 auto;
  height: 600px; }
.sl {
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover; } }

.slide1::after {
  background: url(../images/slide1.jpg) no-repeat center;
  animation: fade1 8s infinite;
  opacity: .2; }
.slide2::after {
  background: url(../images/slide2.jpg) no-repeat center;
  animation: fade2 8s infinite;
  opacity: .2; }
.slide3::after {
  background: url(../images/slide3.jpg) no-repeat center;
  animation: fade3 8s infinite;
  opacity: .2; }
.slide11::after {
  background: url(../images/slide11.jpg) no-repeat center;
  animation: fade11 8s infinite; }
.slide22::after {
  background: url(../images/slide22.jpg) no-repeat center;
  animation: fade22 8s infinite; }
.slide33::after {
  background: url(../images/slide33.jpg) no-repeat center;
  animation: fade33 8s infinite; }

@keyframes fade1 {
  0% {
    opacity: .2; }
  33.333% {
    opacity: 0; }
  66.666% {
    opacity: 0; }
  100% {
    opacity: .2; } }
@keyframes fade2 {

  0% {
    opacity: 0; }
  33.333% {
    opacity: .2; }
  66.666% {
    opacity: 0; }
  100% {
    opacity: 0; } }
@keyframes fade3 {
  0% {
    opacity: 0; }
  33.333% {
    opacity: 0; }
  66.666% {
    opacity: .2; }
  100% {
    opacity: 0; } }
@keyframes fade11 {
  0% {
    opacity: 1; }
  33.333% {
    opacity: 0; }
  66.666% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fade22 {

  0% {
    opacity: 0; }
  33.333% {
    opacity: 1; }
  66.666% {
    opacity: 0; }
  100% {
    opacity: 0; } }
@keyframes fade33 {
  0% {
    opacity: 0; }
  33.333% {
    opacity: 0; }
  66.666% {
    opacity: 1; }
  100% {
    opacity: 0; } }


.main-text {
  position: absolute;
  z-index: 2;
  left: 40px;
  bottom: 40px; }
.main-h {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.75rem;
  line-height: 2rem;
  padding: 12px 120px 12px 40px;
  color: #fff;
  span {
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 2.5rem; }
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    @include angleGradient;
    box-shadow: 6px 12px 15px rgba(0,0,0,0.4);
    z-index: -1; } }

.main-soc {
  position: absolute;
  height: 70%;
  top: 15%;
  right: 20px;
  z-index: 2;
  .soc-line {
    display: block;
    width: 1px;
    height: 125px;
    padding: 60px 0;
    margin: 15px auto 16px;
    background-color: #EBEBEB; }
  .social {
    width: 33px;
    height: 33px;
    margin: 7px 0;
    display: block;
    background-size: contain;
    &.ico-fb {
      background: url(../images/soc-fb.svg) no-repeat center; }
    &.ico-yt {
      background: url(../images/soc-yt.svg) no-repeat center; }
    &.ico-tw {
      background: url(../images/soc-tw.svg) no-repeat center; }
    .soc-link {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: -2;
        background-color: transparent;
        transition: background-color .3s ease;
        border-radius: 100%; }
      &:hover::after {
        background-color: rgba(240,95,23,1); } } } }

//section2
.section2 {
  position: relative;
  padding-bottom: 40px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $contentbg;
    z-index: -2; }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/contentbg.svg);
    opacity: .3;
    z-index: -1; }
  .caption {
    color: #fff; } }

.caption {
  text-align: center;
  font-size: 1.6rem;
  padding: 45px 0;
  h2 {
    margin-bottom: 0.5rem;
    font-family: $font900;
    text-transform: uppercase; }
  .gradCorn {
    position: relative;
    display: inline-block;
    padding: 5px 30px;
    color: #fff;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      @include angleGradient;
      z-index: -1; } } }

.intro {
  display: flex;
  justify-content: space-between;
  color: #fff;
  >div {
    width: 47%;
    margin: 1.5%;
    background-color: rgba(0,0,0,.5);
    padding: 20px; }
  .intro-text {
    position: relative;
    padding: 20px;
    line-height: 1.3rem;
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      right: -65px;
      width: 127px;
      height: 25px;
      background: url(../images/arrow.svg) no-repeat center;
      background-size: contain; }
    p {
      padding: 20px;
      border: 1px dashed #fff; } } }

.youtube {
    background-color: #000;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 100%; }

.youtube {
  img {
    position: absolute;
    opacity: 0.8;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 100%; } }

.youtube {
  .play-button {
    width: 90px;
    height: 60px;
    background-color: #333;
    box-shadow: 0 0 30px rgba( 0,0,0,0.6);
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px; } }

.youtube {
  .play-button {
    &::before {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      content: "";
      border-style: solid;
      border-width: 15px 0 15px 26.0px;
      border-color: transparent transparent transparent #fff; } } }

%cur {
  cursor: pointer; }

%pos {
  position: absolute; }
.youtube {
  .play-button {
    @extend %cur; }
  img {
    @extend %cur; } }
.youtube {
  img  iframe, .play-button {
    position: absolute;
    &::before {
     @extend %pos; } } }

.youtube {
  .play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); } }
.youtube {
  .play-button {}
  &::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); } }

.youtube {
  iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; } }

//section3
.section3 {
  padding-bottom: 40px;
  .caption {
    color: $textColor; } }

.why-text {
  max-width: 700px;
  text-align: center;
  margin: 0 auto 40px; }

.what-we-do {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  >div {
    width: 25%;
    padding: 0 10px; }
  .ways {
    text-align: center;
    .ways-image {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      &.way1 {
        background-image: url(../images/way1.png); }
      &.way2 {
        background-image: url(../images/way2.png); }
      &.way3 {
        background-image: url(../images/way3.png); }
      &.way4 {
        background-image: url(../images/way4.png); } }
    .ways-title {
      font-weight: bold;
      font-size: 1.25rem;
      padding: 15px 0; }
    .ways-text {
      font-size: 0.9rem; } } }
.what-ex {
  position: relative;
  max-width: 980px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  &::after, &::before {
    position: relative;
    width: 150px;
    display: block;
    content: '';
    height: 1px;
    background-color: $contentbg;
    margin: 20px auto; } }

//section4
.section4 {
  position: relative;
  color: #fff;
  font-weight: bold;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $contentbg;
    z-index: -2; }
  .content {
    display: flex;
    justify-content: center;
    >div {
      min-height: 450px; }
    .rest-image {
      width: 417px;
      height: auto;
      background: url(../images/rest.png) no-repeat left;
      background-size: cover; } } }

.grad {
  width: 400px;
  padding: 40px 30px;
  height: auto;
  @include equalGradient; }

.section4 {
  .restavration {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-family: $font900;
    margin-bottom: 40px; }
  p {
    line-height: 1.25rem;
    margin-bottom: 30px; }
  .rest-tels {
    margin-bottom: 70px;
    a {
      color: #fff;
      font-family: $font900;
      font-size: 1.3rem; } }
  .rest-mail {
    a {
      color: #fff;
      font-size: 1.1rem; } } }

//section5
.section5 {
  color: #fff;
  padding: 50px 0;
  .house {
    display: flex;
    justify-content: space-between;
    .house-view {
      width: (100% / 3);
      margin: 0 auto;
      .h-image {
        min-height: 250px;
        margin: 0 5px 5px;
        &.h-1 {
          background: url(../images/h-1.jpg) no-repeat center;
          background-size: cover; }
        &.h-2 {
          background: url(../images/h-2.jpg) no-repeat center;
          background-size: cover;
          min-height: 505px;
          margin: 0 0 5px; }
        &.h-3 {
          background: url(../images/h-3.jpg) no-repeat center;
          background-size: cover; }
        &.h-4 {
          background: url(../images/h-4.jpg) no-repeat center;
          background-size: cover; } } } } }
.h-text {
  min-height: 250px;
  position: relative;
  margin: 0 5px 5px;
  padding: 35px 15px 15px;
  font-weight: bold;
  line-height: 1.4rem;
  text-align: center;
  max-height: 250px;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $contentbg;
    z-index: -2; }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/contentbg.svg);
    background-size: contain;
    opacity: .6;
    z-index: -1; } }

//footer
footer {
  background-color: $contentbg; }
.foot-wrap {
  .contacts {
    display: flex;
    justify-content: space-between;
    max-width: 840px;
    margin: 0 auto 20px;
    padding-top: 40px; } }

.foot-soc {
  position: relative;
  height: 90px;
  .main-soc {
    text-align: center;
    position: relative;
    z-index: inherit;
    height: auto;
    top: inherit;
    right: inherit;
    transform: translate(0,0);
    .soc-line {
      display: inline-block;
      height: 1px;
      width: 100% / 2 - 6%;
      padding: 0;
      margin: 0 0 22px; }
    .social {
      display: inline-block;
      width: 3%;
      &:hover {
        opacity: 0.7; }
      .soc-link {
        width: 33px;
        height: 33px;
        margin: 0 auto;
        &::after {
          display: none; } } } } }


@media screen and (max-width:1366px) {
  .main-slider {
    display: none; }
  .upper-slider {
    position: relative; }
  .content {
    max-width: 1366px; }
  .foot-soc {
    .main-soc {
      .soc-line {
        width: 100% / 2 - 10%; }
      .social {
        width: 5%; } } }
  .h-text {
    padding-top: 15px;
    line-height: 1.2rem; } }

@media screen and (max-width:1023px) {
  html {
    font-size: 17px; }
  .head-wrap {
    .contacts-mail, .contacts-loc {
      display: none; } }
  .main-soc {
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    .soc-line {
      display: none; } }
  header {
    height: 99px; }
  .head-wrap {
    position: fixed;
    width: 100%;
    z-index: 10; }
  .contacts {
    >div {
      margin-left: 0; } }
  .burger-wrap {
    position: absolute;
    top: 33px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 34px;
    .b-line {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #fff;
      left: 0;
      transition: transform .5s ease, left .5s ease, top .5s ease, opacity.25s ease;
      &.b-top {
        top: 0; }
      &.b-cen {
        top: 16px; }
      &.b-bot {
        top: 32px; } }
    &.opened {
      .b-top {
        transform: rotate(33deg);
        left: 0px;
        top: 17px; } }
    &.opened {
      .b-cen {
        opacity: 0; } }
    &.opened {
      .b-bot {
        transform: rotate(-33deg);
        left: 0px;
        top: 17px; } } }
  .nav {
    background-color: #131213;
    position: fixed;
    top: 100px;
    left: 0;
    width: 100%;
    height: auto;
    display: block;
    transform: translateY(-150%);
    transition: transform .5s ease;
    z-index: 3;
    ul {
      display: block;
      li {
        display: block;
        padding: 5px 0;
        text-align: center;
        &::before {
          width: 100%;
          height: 1px; }
        .nav-link {
          display: block;
          min-width: 200px;
          padding: 10px 30px; } } } }
  .burger-menu.opened {
    transform: translateY(0); }
  .slider, .sl {
    height: 400px; }
  .what-we-do {
    flex-wrap: wrap;
    .ways {
      margin-bottom: 30px;
      .ways-image {
        width: 100%; } }
    >div {
      width: 50%; } }
  .section5 {
    .house {
      display: block;
      .h-text {
        min-height: inherit;
        &::after {
          display: none; } }
      .house-view {
        width: 100%;
        .h-image {
          display: none; } } } }
  .foot-wrap {
    .contacts {
      padding-top: 20px;
      display: block;
      margin: 0 auto;
      max-width: inherit;
      text-align: center; }
    .contacts-ico {
      float: none;
      margin: 10px auto 0;
      &.ico-mail {
        margin: 0 auto 10px; } }
    .contacts-text {
      float: none;
      &.in-one-line {
        line-height: 26px; } } }
  .foot-soc {
    height: auto;
    padding-bottom: 20px; }
  .foot-soc {
    .main-soc {
      .social {
        width: 12%; }
      .soc-link {
        margin: 0 20px; }
      .soc-line {
        display: none; } } }
  .breadcrumbs {
    padding: 30px 0; } }

@media screen and (max-width:767px) {
  html {
    font-size: 16px; }
  header {
    height: 71px; }
  .nav {
    top: 72px; }
  .burger-wrap {
    top: 19px;
    width: 40px; }
  .head-wrap {
    .contacts {
      padding: 10px 0; }
    .contacts-ico {
      display: none; }
    .logo-wrap {
      a {
        width: 137px; } } }
  .slider {
    background: url(../images/slide-mobile.jpg) no-repeat center;
    background-size: cover;
    height: 300px;
    .sl {
      display: none; } }
  .main-text {
    left: 50%;
    transform: translateX(-50%); }
  .main-soc {
    top: 20px;
    right: 0;
    width: 100%;
    text-align: center;
    transform: translate(0,0);
    .social {
      display: inline-block;
      margin: 0 10px; } }
  .main-h {
    padding: 5px 10px; }
  .main-h {
    padding: 12px 30px;
    font-size: 1.3rem;
    line-height: 1.5rem;
    span {
      font-size: 1.5rem;
      line-height: 2rem; } }
  .caption {
    font-size: 1.2rem; }
  .intro {
    display: block;
    .intro-text {
      &::after {
        display: none; } }
    >div {
      width: 100%;
      margin: 0 auto 20px; }
    .intro-video {
      iframe {
        height: 250px; } } }
  .section2 {
    padding-bottom: 10px; }
  .section4 {
    .rest-tels {
      margin-bottom: 30px; }
    .content {
      &>div {
        text-align: center;
        min-height: inherit; }
      .rest-image {
        display: none; } } }
  .section5 {
    padding: 20px 0; }

  .breadcrumbs {
    h1 {
      font-size: 1.5rem; } } }


@media screen and (max-width:567px) {
  .what-we-do {
    margin-bottom: 20px;
    >div {
      width: 100%; } } }


@media screen and (max-width:374px) {
  header {
    height: 61px; }
  .nav {
    top: 62px; }
  .burger-wrap {
    top: 12px;
    width: 36px; }
  .head-wrap {
    .contacts {
      .contacts-text {
        line-height: 20px; }
      a {
        font-size: 0.8rem; } }
    .logo-wrap {
      a {
        width: 110px; } } }
  .main-h {
    padding: 10px 15px; } }
